import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./services.scss"

import Service from "./service"

import Bulldozer from "../images/icons/ecology.png"
import Tassels from "../images/icons/hand-shake.png"
import Ruler from "../images/icons/team.png"

const Services = () => {
  const card = [
    {
      title: `Sustainability`,
      icon: Bulldozer,
      description: ` We Incorporate green building materials, energy-efficient technologies, and sustainable design principles in your projects. `,
    },
    {
      title: `partnerships`,
      icon: Tassels,
      description: `We collaborate with suppliers, contractors, and subcontractors that share your commitment to sustainability.`,
    },
    {
      title: `Inclusivity`,
      icon: Ruler,
      description: ` As a woman-owned company, we emphasize in diversity and inclusivity in our recruitment and talent management strategies.`,
    },
  ]
  return (
    <div className="services" id="services">
      <Container>
        <Row>
          {card.map(service => {
            return (
              <Col md="6" lg="4">
                <Service services={service} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Services
